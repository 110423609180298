import { getProjectElementsData } from '../components/projectPageWidget/panel/showHideData';
import { getFeaturedCollectionElementsData } from '../components/featuredCollectionWidget/panel/showHideData';
import { getCollectionElementsData } from '../components/collectionPageWidget/panel/showHideData';
import { getPortfolioElementsData } from '../components/portfolioPageWidget/panel/showHideData';
import { MainWidgetsIds } from '../constants';
import { FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import { ComponentRef } from '@wix/platform-editor-sdk';
import { FeaturedCollectionWidgetWidgetProps } from '../components/featuredCollectionWidget/config/types';
import {
  getCollections,
  getCollectionsWithHidden,
  getPortfolioSettings,
} from '../services/dataServices';
import { IPanelsApi } from '@wix/blocks-widget-services/dist/types/panels';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import _ from 'lodash';

interface OpenPortfolioSettingsPanelConfig {
  httpClient: IHttpClient;
  editorSDK: FlowEditorSDK;
  componentRef: ComponentRef;
  panelsApi: IPanelsApi;
  panelIdentifier: string;
  t: any;
}

export const getPanelData = async (
  t: any,
  editorSDK: FlowEditorSDK,
  currentWidgetId: string,
  widgetRef: ComponentRef,
) => {
  let panelData = {
    categoriesData: [] as any,
    elementsData: [] as any,
  };
  switch (currentWidgetId) {
    case MainWidgetsIds.PORTFOLIO_PAGE:
      panelData = getPortfolioElementsData(t);
      break;
    case MainWidgetsIds.COLLECTION_PAGE:
      panelData = getCollectionElementsData(t);
      break;
    case MainWidgetsIds.PROJECT_PAGE:
      panelData = getProjectElementsData(t);
      break;
    case MainWidgetsIds.FEATURE_COLLECTION:
      const props = (await editorSDK.application.appStudioWidgets.props.get(
        '',
        {
          widgetRef,
        },
      )) as FeaturedCollectionWidgetWidgetProps;
      const numOfSelectedCollections =
        props.data?.selectedCollections?.length || 0;
      panelData = getFeaturedCollectionElementsData(
        t,
        numOfSelectedCollections,
      );
      break;

    default:
      break;
  }
  return panelData;
};

export const openPortfolioSettingsPanel = async ({
  httpClient,
  editorSDK,
  componentRef,
  panelsApi,
  panelIdentifier,
  t,
}: OpenPortfolioSettingsPanelConfig) => {
  const collections = await getCollections(httpClient);
  const currentSettings = await getPortfolioSettings(httpClient);
  const isSubMenuEnabled =
    currentSettings.data.portfolioSettings?.siteMenuSettings?.autoUpdateMenu;
  const portfolioSettingsRevision =
    currentSettings.data.portfolioSettings?.revision;
  const isSingleCollectionPortfolio = collections.length === 1;
  const existingProps = await editorSDK.application.appStudioWidgets.props.get(
    'token',
    { widgetRef: componentRef },
  );
  editorSDK.application.appStudioWidgets.props.set('token', {
    widgetRef: componentRef,
    newProps: {
      ...existingProps,
      portfolioSettingsRevision,
      subMenuPanelText: {
        toggleLabel: t('Settings_GFPP_Submenu_ToggleLabel'),
        enabled: isSingleCollectionPortfolio
          ? t('Settings_GFPP_Submenu_Enabled_SingleCollection')
          : t('Settings_GFPP_Submenu_Enabled_MultipleCollections'),
        disabled: t('Settings_GFPP_Submenu_DisabledText'),
      },
      isSubMenuEnabled,
    },
  });
  await panelsApi.openBlocksPanel(panelIdentifier, componentRef);
};

export const openFeatureCollectionPanel = async ({
  httpClient,
  editorSDK,
  componentRef,
  panelsApi,
  panelIdentifier,
  t,
}: OpenPortfolioSettingsPanelConfig) => {
  const collections = (await getCollectionsWithHidden(httpClient)).map(
    (collection) => {
      const { id, title, slug } = collection;
      return { id, title, slug };
    },
  );
  const existingProps: FeaturedCollectionWidgetWidgetProps =
    await editorSDK.application.appStudioWidgets.props.get('token', {
      widgetRef: componentRef,
    });
  let selectedCollections = existingProps?.data?.selectedCollections || [];
  let selectedCollectionDataSample =
    existingProps?.data?.selectedCollectionDataSample;

  const isAllSelectedExist = selectedCollections.every(
    (selectedCollection: string) => {
      const isExist = !!_.find(collections, ['id', selectedCollection]);
      return isExist;
    },
  );

  if (
    selectedCollections.length > 0 &&
    !isAllSelectedExist &&
    !!selectedCollectionDataSample
  ) {
    const duplicatedCollections = collections.filter((collection) => {
      const { title, slug } = collection;
      const isInclude = _.find(selectedCollectionDataSample, { title, slug });
      return !!isInclude;
    });
    if (duplicatedCollections.length > 0) {
      selectedCollections = duplicatedCollections.map(
        (collection) => collection.id || '',
      );
      selectedCollectionDataSample = duplicatedCollections.map((collection) => {
        const { title, slug } = collection;
        return { title, slug };
      });
    }
  }
  await editorSDK.application.appStudioWidgets.props.set('', {
    widgetRef: componentRef,
    newProps: {
      ...existingProps,
      data: {
        ...existingProps?.data,
        selectedCollections,
        selectedCollectionDataSample,
      },
    },
  });
  await panelsApi.openBlocksPanel(panelIdentifier, componentRef);
};
