import { handleFirstInstall } from './first-install/handleFirstInstall';
import {
  setPortfolioPagesStates,
  addPortfolioProjectsIfNeeded,
} from '../utils/pagesUtils';
import { editorAppEventListener } from './editorAppEventListener';
import { EditorScriptFlowAPI, FlowEditorSDK ,FlowPlatformOptions} from '@wix/yoshi-flow-editor';
import { AppDefId } from '../constants';

export const onEditorReady = async (
  editorSDK: FlowEditorSDK,
  appDefId: string,
  options: FlowPlatformOptions,
  flowAPI: EditorScriptFlowAPI,
): Promise<void> => {
  const addPortfolioProjectsRouter = flowAPI.experiments.enabled(
    'specs.portfolio.addPortfolioProjectsRouter',
  );

  if (options.firstInstall) {
    const areAppPagesInstalled = await editorSDK.pages.getApplicationPages('');
    if (areAppPagesInstalled.length >= 3) {
      await editorSDK.editor.deeplink.show('', {
        type: 'pagesPanel',
        params: [AppDefId],
      });
    } else {
      flowAPI.fedops.interactionStarted('portfolio-first-install')
      await handleFirstInstall(editorSDK, appDefId, options, flowAPI);
      flowAPI.fedops.interactionEnded('portfolio-first-install')
    }
  }
  setPortfolioPagesStates(editorSDK);
  editorAppEventListener(editorSDK, flowAPI, options.origin.type);

  if (addPortfolioProjectsRouter) {
    addPortfolioProjectsIfNeeded(editorSDK);
  }
};
